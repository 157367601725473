<template>
  <div class="main">
    <b-tabs>
      <br>
      <b-tab title="AWS" active>
        <AwsPerseusForm />
      </b-tab>
      <b-tab title="Azure">
        <AzurePerseusForm />
      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
// @ is an alias to /src
import AwsPerseusForm from '@/components/AwsPerseusForm.vue'
import AzurePerseusForm from '@/components/AzurePerseusForm.vue'

export default {
  name: 'Onboard',
  components: {
    AwsPerseusForm,
    AzurePerseusForm
  }
}
</script>

<style scoped>
.main {
    margin: 0 auto;
    max-width: min(60rem, 80vw);
}
</style>