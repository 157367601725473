<template>
    <div>
        

        <!-- alert banner -->
        <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

            <!-- loader -->
            <div v-if="loading" class="loading" style="margin-bottom: 1rem;">
                <b-spinner type="grow" label="Loading..."></b-spinner> {{ loadingMessage }}
            </div>

            <b-card style="margin-bottom: 1rem;">
                <b-card-text>

                    <!-- Contract issue count -->
                    <b-alert 
                        :variant="contractCount===0 ? 'info' : 'warning'" 
                        show 
                    >
                        <strong>{{ contractCount }}</strong> contracts with issues; <strong>{{ activeContractCount }}</strong> active contracts with issues
                    </b-alert>

                    <!-- Rereport and refresh buttons -->
                    <b-alert variant="info" show>
                        <b-button 
                            pill @click="confirmReportJob"
                            style="margin-right: 1rem;"
                        >
                            <b-icon icon="plus" /> Run report job
                        </b-button>
                        <b-button 
                            pill @click="reloadReport"
                            style="margin-right: 1rem;"
                        >
                            <b-icon icon="arrow-clockwise" /> Reload results
                        </b-button>

                        <router-link 
                            :to="`/saffron/contracts/${saffronReportContract.ContractId}`"
                            style="margin-right: 1rem;"
                            target='_blank'
                        >
                            <b-icon icon="box-arrow-up-right" /> AWS report contract
                        </router-link>

                        <router-link
                             v-if="lastReportJobId" 
                            :to="`/saffron/jobs/${lastReportJobId}`"
                            target='_blank'
                        >
                            <b-icon icon="box-arrow-up-right" /> Report job is running
                        </router-link>
                        
                    </b-alert>

                </b-card-text>
            </b-card>


            <!-- User Interface controls -->
            <b-row style="margin-bottom: 1rem;">

            <b-col lg="6" class="my-1">
                <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                >
                <b-input-group size="sm">
                    <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                </b-input-group>
                </b-form-group>
            </b-col>

            <b-col lg="6" class="my-1">
                <b-form-group
                v-model="sortDirection"
                label="Filter On"
                description="Leave all unchecked to filter on all data"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                v-slot="{ ariaDescribedby }"
                >
                <b-form-checkbox-group
                    v-model="filterOn"
                    :aria-describedby="ariaDescribedby"
                    class="mt-1"
                >
                    <b-form-checkbox v-for="column in filterableColumns" :key="column" :value="column">{{column}}</b-form-checkbox>
                </b-form-checkbox-group>
                </b-form-group>
            </b-col>

            <b-col sm="5" md="6" class="my-1">
                <b-form-group
                label="Per page"
                label-for="per-page-select"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
                >
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
                </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
                ></b-pagination>
            </b-col>
            </b-row>

            <!-- table -->
            <b-table 
                ref="contractTable"
                striped hover 
                :key="`contractTable-${contractCount}`"
                :items="contracts" 
                :fields="contractFields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                responsive
                bordered
                @filtered="onFiltered"
            >

                <!-- template for link to resource -->
                <template #cell(ContractId)="data">
                    <b-button pill variant="outline-secondary" size="sm" @click="showManageModal(data.item, data.index, $data.target)" class="mr-1">
                    Manage
                    </b-button>
                </template>

                <template v-slot:cell(EntityId)="data">
                    <router-link 
                        :to="`/${data.item.EntityType === 'AwsAccount' ? 'accounts' : 'resources'}/${data.item.EntityId}`"
                        target='_blank'
                    >
                        <b-icon icon="box-arrow-up-right" />
                    </router-link>
                </template>

            </b-table>

            <!-- ManageModal modal -->
            <b-modal 
            :id="manageModal.id" 
            :title="manageModal.title"
            size="xl" hide-footer
            @hide="resetManageModal"
            >
                <SaffronContract :contractId="manageModal.ContractId" />
                <template #modal-title>
                        <router-link 
                            :to="`/saffron/contracts/${manageModal.ContractId}`"
                            target="_blank"
                        >
                            <b-icon icon="box-arrow-up-right" />
                        </router-link>
                        {{ manageModal.title }} 
                </template>
            </b-modal>

    </div>
</template>

<script>
import SaffronContract from '@/components/SaffronContract.vue'
import mixins from '@/mixins';
export default {
  name: 'Saffron',
  mixins: [mixins],
  components: {
    SaffronContract
  },
  data() {
    return {
        error: '',
        loading: true,
        loadingMessage: 'Loading contracts..',
        contracts: [],
        contractFields: [
            {
                key: 'CustomerShortName',
                sortable: true
            },
            {
                key: 'AccountAlias',
                sortable: true
            },
            {
                key: 'AccountId',
                sortable: true
            },
            {
                key: 'Active',
                sortable: true
            },
            {
                key: 'ConfigurationType',
                sortable: true
            },
            {
                key: 'ContractId',
                label: 'Contract',
                sortable: false
            },
            {
                key: 'CloudResourceId',
                sortable: true
            },
            {
                key: 'Status',
                sortable: true
            },
            {
                key: 'Region',
                sortable: true
            },
            {
                key: 'Name',
                sortable: true
            },
            {
                key: 'EntityId',
                label: 'Entity',
                sortable: false
            },
            {
                key: 'EntityType',
                label: 'EntityType',
                sortable: true
            }
        ],

        // the SaffronSystem.AwsReportContract
        saffronReportContract: {},
        lastReportJobId: '',

        // table filter stuff
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 50, 100, { value: 10000, text: "Show a lot" }],
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        filterableColumns: [
            'AccountAlias', 'Region', 'CustomerShortName', 'Status', 'Active',
            'CloudResourceId', 'EntityType', 'AwsAccountId', 'Name', 'ConfigurationType'
        ],

        // management modal for contract
        manageModal: {
          id: 'manage-modal',
          title: '',
          ContractId: ''
        }
    }
  },
  computed: {
      contractCount: function() {
          return this.contracts.length
      },
      activeContractCount: function() {
          return this.contracts.filter(contract => contract.Active).length
      }
  },
  mounted () {

    // reload all report data  
    this.reloadReport()

    // get the SaffronSystem.AwsContractsReport refresh contract
    this.loadReportContract()

  },
  methods: {
    async reloadReport() {
        
        this.contracts = [];
        console.log('Loading accounts')

        // paginate all aws accounts
        for await (const page of this.paginateAutomationApi('/cacmdb/accounts', {limit: 40, type: "aws"})){

            // for each account, load the corresponding report contract
            for (const account of page.Items){
                
                // request report
                this.automationApiRequest(`/saffron/contracts?${this.urlEncodeObj({
                    ConfigurationType: "ReviewAccountContracts",
                    EntityId: account.accountid
                })}`).then(reports => {

                    // get the report if it exists
                    if (reports.Items) {
                        let contract = reports.Items[0];

                        // add any relevant data to the aggregated report
                        if (contract.Status === 'Warning'){
                            contract.Outputs.IssueItems.forEach(issue => {
                                this.contracts.push(Object.assign(issue, contract.Outputs.CommonMeta))
                            })
                        }
                    }

                    // update totalRows on each page
                    this.totalRows = this.contracts.length

                }).catch(err => {
                    this.error = this.getErrorString(err)
                }) 
            }

        }
        this.loading = false
    },
    async loadReportContract() {

        await this.automationApiRequest(
            '/saffron/contracts?EntityType=SaffronSystem&ConfigurationType=AwsContractsReport',
            'GET'
        ).then(response => {
            this.saffronReportContract = response.Items[0]
            
        }).catch(err => {
            this.error = this.getErrorString(err)
        })

        return this.saffronReportContract

    },
    confirmReportJob() {

        // reload Report contract; see if it is locked
        this.loadReportContract().then(reportContract => {

            if (reportContract.LockedBy){
                this.lastReportJobId = reportContract.LockedBy
            } else {
                this.automationApiRequest(
                    '/saffron/jobs',
                    'POST',
                    {
                        ContractId: reportContract.ContractId,
                        Action: 'Refresh'
                    }
                ).then(response => {
                    this.lastReportJobId = response.JobId
                }).catch(err => {
                    this.error = this.getErrorString(err)
                })
            }

        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showManageModal(item, index, button) {
      this.manageModal.title = `${item.ConfigurationType} Contract`
      this.manageModal.ContractId = item.ContractId
      this.$root.$emit('bv::show::modal', this.manageModal.id, button)
    },
    resetManageModal() {
      this.manageModal.title = ''
      this.manageModal.content = ''
    }
  }
}
</script>