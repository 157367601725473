<template>
  <div class="container">

      <!-- searchbar -->
      <input class="searchbar" placeholder="Search accounts or resources..." v-model="searchInput" @keyup.enter="search">

      <!-- loader -->
      <div v-if="isSearching" class="loading">
        <b-spinner type="grow" label="Loading..."></b-spinner> Searching for matches...
      </div>

      <!-- prompt user to search for something -->
      <p v-if="!hasSearched && accounts.length == 0 && resources.length == 0">Search for accounts or resources to display results</p>

      <!-- no results -->
      <p v-if="hasSearched  && !isSearching && accounts.length == 0 && resources.length == 0">No results.</p>

      <!-- account list -->
      <div v-if="accounts.length > 0" class="o2-item-pane">
        <h3>Accounts</h3><hr>
        <div class="o2-item-grid">
          <div 
            :key="account.id"  
            v-for="account in accounts" class="o2-grid-item"
            @dblclick="navigateToItem(account)"
          >
              <span class="o2-grid-item-line">{{ account.connectria.shortname || account.accountid }}</span>
              <span class="o2-grid-item-line">{{ account.aws.alias || account.accountid }}</span>  
          </div>
        </div>
      </div>

      <!-- resource list -->
      <div v-if="resources.length > 0" class="o2-item-pane">
        <h3>Resources</h3><hr>
        <div class="o2-item-grid">
          <div 
            :key="resource.id"  
            v-for="resource in resources" class="o2-grid-item"
            @dblclick="navigateToItem(resource)"
          >
              <span class="o2-grid-item-line">{{ resource.region }}</span>
              <span class="o2-grid-item-line">{{ resource.cloudresourceid }}</span>  
          </div>
        </div>
      </div>

  </div>
</template>
<script>
import mixins from '@/mixins';
export default {
  name: 'Search',
  mixins: [mixins],
  data () {
      return {

          // value input into searchbar
          searchInput: '',

          // list of accounts
          accounts: [],

          // resources to display
          resources: [],

          // whether a search has been executed
          hasSearched: false,

          // whether to show loading
          searchingStatus: {
            accountsById: false,
            accountsByShortName: false,
            resourcesById: false,
            resourcesByCloudResourceId: false,
            resourcesByAccountId: false,
            resourcesByAccountResults: false
          }

      }
  },
  mounted () {
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  computed: {
    isSearching: function() {
      // return true if any is true in this.searchingStatus
      return Object.values(this.searchingStatus).some(val => val)
    },
    encodedInput: function() {
      return encodeURIComponent(this.searchInput)
    }
  },
  watch: {
    accounts: function () {
      this.searchResourcesByAccounts()
    }
  },
  methods: {
    async searchAccounts() {

      console.log('Searching accounts...')
      this.accounts = [];

      // search for exact account id match
      this.searchingStatus.accountsById = true;
      this.automationApiRequest('/cacmdb/accounts/' + this.encodedInput).then(accountIdMatch => {
        if (accountIdMatch) {
          this.accounts.push(this.addDefaultAccountValues(accountIdMatch))
        }
        this.searchingStatus.accountsById = false;
      }).catch(() => {
        // ok for this to fail; returns 404 if not found
        this.searchingStatus.accountsById = false
      })

      // search for customer shortname
      this.searchingStatus.accountsByShortName = true;
      this.automationApiRequest('/cacmdb/accounts?connectria.shortname=' + this.encodedInput).then(customerMatches => {
        if (customerMatches.Items) {
          customerMatches.Items.forEach(account => {
            this.accounts.push(this.addDefaultAccountValues(account))
          })
        }
        this.searchingStatus.accountsByShortName = false;
      })
    },
    searchResources(){
      console.log('Searching resources...')
      this.resources = [];

      // search for exact resource id match
      this.searchingStatus.resourcesById = true;
      this.automationApiRequest('/cacmdb/resources/' + this.encodedInput).then(resourceIdMatch => {
        if (resourceIdMatch) {
          this.resources.push(this.addDefaultResourceValues(resourceIdMatch))
        }
        this.searchingStatus.resourcesById = false;
      }).catch(() => {
        // ok for this to fail; returns 404 if not found
        this.searchingStatus.resourcesById = false
      })

      // search for resource by cloud resource id
      this.searchingStatus.resourcesByCloudResourceId = true;
      this.automationApiRequest('/cacmdb/resources?cloudresourceid=' + this.encodedInput).then(cloudResourceIdMatches => {
        if (cloudResourceIdMatches.Items) {
          cloudResourceIdMatches.Items.forEach(resource => {
            this.resources.push(this.addDefaultResourceValues(resource))
          })
        }
        this.searchingStatus.resourcesByCloudResourceId = false;
      })

      // search for resource by cloud resource id
      this.searchingStatus.resourceByUri = true;
      this.automationApiRequest('/cacmdb/resources?uri=' + this.encodedInput).then(uriMatches => {
        if (uriMatches.Items) {
          uriMatches.Items.forEach(resource => {
            this.resources.push(this.addDefaultResourceValues(resource))
          })
        }
        this.searchingStatus.resourceByUri = false;
      })

      // search for resource by account id
      this.searchingStatus.resourcesByAccountId = true;
      this.automationApiRequest('/cacmdb/resources?limit=30&accountid=' + this.encodedInput).then(matches => {
        if (matches.Items) {
          matches.Items.forEach(resource => {
            this.resources.push(this.addDefaultResourceValues(resource))
          })
        }
        this.searchingStatus.resourcesByAccountId = false;
      })
    },
    async searchResourcesByAccounts () {
      this.searchingStatus.resourcesByAccountResults = true;
      this.accounts.forEach(account => {
        this.automationApiRequest('/cacmdb/resources?limit=30&exists=true&accountid=' + account.accountid).then(matches => {
          if (matches.Items) {
            this.resources = this.resources.concat(matches.Items.map(resource => 
                this.addDefaultResourceValues(resource)
              ))
          }
        })
      })
      this.searchingStatus.resourcesByAccountResults = false;
    },
    search() {
      if (this.searchInput){
        this.hasSearched = true;
        // wait for account results; base resource search off account results
        this.searchAccounts();
        this.searchResources();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.searchbar {
  width: 100%;
  padding: 10px 18px;
  font-size: 18px;
  margin-bottom: 16px;
  &:focus {
    outline: 0;
  }
}

.loading {
  width: 100%;
  text-align: center;
}

.container {
  margin: 0 auto;
  max-width: 70rem;
}
</style>
