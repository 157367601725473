<template>
    <div>
        <b-tabs class="main" content-class="mt-3">
            <b-tab title="Identities" active>
                <p>Identities</p>

                <!-- loader -->
                <div v-if="loadingIdentities" class="loading">
                    <b-spinner type="grow" label="Loading..."></b-spinner> Searching for identites...
                </div>

                <!-- table -->
                <b-table 
                    v-else 
                    striped hover
                    show-empty
                    responsive
                    stacked="md" 
                    :items="identities" 
                    :fields="identityFields"
                >
                    <template v-slot:cell(grantsAsString)="row">
                        <b-form-input v-if="editingIdentityId == row.item.id" v-model="row.item.grantsAsString"/>
                        <div @dblclick="startEditing(row.item.id)" v-else>{{ row.item.grantsAsString }}</div>
                    </template>
                </b-table>
            </b-tab>

            <b-tab title="Trail">
                <p>API Call Trail</p>
                
                <!-- loader -->
                <div v-if="loadingIdentities" class="loading">
                    <b-spinner type="grow" label="Loading..."></b-spinner> Loading peppermint trail..
                </div>

                <!-- table -->
                <b-table 
                    v-else 
                    striped hover 
                    show-empty
                    responsive
                    stacked="md"
                    :items="trail" 
                    :fields="trailFields"
                >
                </b-table>
            </b-tab>

            <b-tab title="update IP" active>
                <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>
                <b-alert variant="info" v-if="info" show>{{ info }}</b-alert>
                <p>IP address that are currently assinged to you</p>
                <b-list-group>
                    <b-list-group-item v-for="ip in ipList" :key="ip">{{ ip }}<b-button style="margin:10px"  @click="deleteIps(ip)" variant="danger" size="sm">x</b-button></b-list-group-item>
                </b-list-group>
                <p>To delete an IP press the red X next to the IP you wish to delete</p>
                <p>Below is where you will enter in your IP address you would like to add</p>
                <b-form>
                    <div id="updateYourIps">
                        <form id="update-ips-form" @submit.prevent="updateIps">
                            <input v-model="userIpInput" style="margin:10px" id="update-ips" type="text">
                            <button type="submit">
                                Submit
                            </button>
                            <label for="update-ips"> New IP to add</label>
                        </form>
                    </div>
                </b-form>
                <b-form>
                    <p>Push up IP after you are done adding it with the button below</p>
                    <div id="pushIpset">
                        <form id="push-ips-form" @submit.prevent="pushIpUpdates">
                            <button type="submit">
                                Push IP
                            </button>
                        </form>
                    </div>
                </b-form>
            </b-tab>
        </b-tabs>

    </div>
</template>

<script>
import mixins from '@/mixins';
export default {
  name: 'Peppermint',
  mixins: [mixins],
  data() {
    return {
        loadingIdentities: true,
        ipList: null,
        userIpInput: "",
        userIpInputdelete: "",
        error: "",
        info: "",
        loadingTrail: true,
        identities: [],
        trail: [],
        editingIdentityId: null,
        identityFields: [
            {
                key: 'id',
                label: 'ID'
            },
            {
                key: 'lastUsedIp',
                label: 'Last IP'
            },
            {
                key: 'lastUsedTimestamp',
                label: 'Last Used'
            },
            {
                key: "recordedIps",
                label: "Recorded IPs"
            },
            {
                key: 'allowedCidrs',
                label: 'Allowed CIDRs'
            }
        ],
        trailFields: [
            {
                key: 'UrlPath'
            },
            {
                key: 'SourceIp'
            },
            {
                key: 'HttpMethod'
            },
            {
                key: 'Scope'
            },
            {
                key: 'Authorized'
            },
            {
                key: 'IdentityId'
            },
            {
                key: 'Timestamp'
            },
            {
                key: 'AllowedCidr'
            }
        ]
    }
  },
  watch: {
      isEditing: {
          deep: true
      }
  },
  mounted () {

    // load identities
    this.automationApiRequest('/peppermint/identities', 'get').then(data => {
        this.identities = data.Items;
        this.identities.forEach(identity => {
            identity.grantsAsString = JSON.stringify(identity.grants)
        });
        this.loadingIdentities = false;
        })

    this.peppWhoAmI()

    this.automationApiRequest('/peppermint/trail', 'get').then(data => {
        this.trail = data.Items;
        this.loadingTrail = false;
        })

  },
  methods: {
      startEditing(identityId) {
          console.log('Start editing ' + identityId)
          this.editingIdentityId = identityId;
      },
      getIpUpdateBody(){
        var validateIp = this.validateIPaddress(this.userIpInput)
        if (validateIp == true)
            if (!this.ipList.includes(this.userIpInput.trim())) {
                this.ipList.push(this.userIpInput.trim())
            return {allowedCidrs:this.ipList}
            }
        else {
            return
        }
      },
      peppWhoAmI(){
        this.automationApiRequest('/peppermint/whoami', 'get').then(response => {
            console.log(response)
            this.ipList = response['allowedCidrs']
        })
      },
      updateIps(){
        this.automationApiRequest('/peppermint/updateWhoAmI', 'put', this.getIpUpdateBody()).then(() => {
            this.info = "updated IP sucssefuly";
        }).catch(err => {
            this.error = this.getErrorString(err)
        })
      },
      pushIpUpdates(){
        this.automationApiRequest('/peppermint/ipset', 'put').then(response => {
            console.log(response)
        })
      },
      validateIPaddress(ipaddress) 
        {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(?:[12][0-9]|3[0-2])$/.test(ipaddress))
        {
            return (true)
        }
        alert("You have entered an invalid IP address!")
        return (false)
        },
      deleteIps(ip){
        var index = this.ipList.indexOf(ip)
        if (confirm("are you sure you want to delete this ip!")) {
            if (index > -1) {
                this.ipList.splice(index, 1);
            }
            this.automationApiRequest('/peppermint/updateWhoAmI', 'put', {allowedCidrs:this.ipList}).then(() => {
                this.info = "deleted IP sucssefuly";
            }).catch(err => {
                this.error = this.getErrorString(err)
            })
        } 
      }
  }
}
</script>

<style scoped lang="scss">

.main {
    margin: 0 5vw;
}

</style>