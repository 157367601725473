<template>
    <div>

        <!-- alert banner -->
        <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

        <!-- loader -->
        <div v-if="loading" class="loading">
            <b-spinner class="o2-bt-spin-sz" type="grow" label="Loading..."></b-spinner> Loading builds...
        </div>

        <!-- no gadgetBuilds message -->
        <p v-if="gadgetBuilds.length === 0 && !loading">No builds found.</p>

        <!-- table -->
        <b-table 
            v-if="gadgetBuilds.length !== 0 && !loading" 
            striped hover 
            :items="gadgetBuilds" 
            :fields="gadgetFields"
            :sort-by="'created'"
            :sort-desc="true"
            show-empty
            responsive
            bordered
            stacked="md"
        >
            <!-- template for link to resource -->
            <template v-slot:cell(id)="data">
                <router-link :to="`/gadget/builds/${data.item.id}`">{{ data.item.id }}</router-link>
            </template>
        </b-table>

    </div>
</template>
<script>
import mixins from '@/mixins';
export default {
  name: 'AccountGadgetTable',
  mixins: [mixins],
  props: {
      accountId: {
            required: true
      },
      accountType: {
            required: true,
            validator: function (value) {
                // The value must match one of these strings
                return ['aws', 'azure'].indexOf(value) !== -1
            }
      }
  },
  data () {
    return {
        error: '',
        loading: true,
        gadgetBuilds: [],
        gadgetFields: [
            {
                "key" : "partition",
                "sortable" : true
            },
            {
                "key" : "id",
                "sortable" : true
            },
            {
                "key" : "date_created",
                "sortable" : true
            },
            {
                "key" : "state_path",
                "sortable" : true
            },
        ],
    }
  },
  computed : {},
  mounted () {

    this.fullAutomationApiResults(
        '/gadget/builds',
        {
            exists: true,
            id: `cloud-automation.customers.${this.accountType}.${this.accountId}.*`
        }
    ).then(response => {
        console.log(response)
        this.gadgetBuilds = response.map(gadget => this.dotPathObject(gadget))
        this.loading = false
    }).catch(err => {
        this.error = this.getErrorString(err)
        this.loading = false
    })
  },
  methods: {}
}
</script>