<template>
    <div style="margin: 0 auto; max-width: 100rem">

        <!-- alert banner -->
        <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

        <!-- loader -->
        <div v-if="loading" class="loading">
            <b-spinner class="o2-bt-spin-sz" type="grow" label="Loading..."></b-spinner> Loading items...
        </div>

        <b-row>
            <b-button 
                v-b-modal.create-basil-modal 
                style="margin-bottom:1rem; margin-right:1rem;"
                variant="info" pill
            >
                <b-icon icon="plus"/> Create
            </b-button>
            <b-modal id="create-basil-modal"  size="lg" title="New Secret" :hide-footer="true">
                <CreateBasilForm :accountId="accountId" />
            </b-modal>
        </b-row>
        <p v-if="secrets.length === 0 && !loading">No secrets found.</p>

        <!-- table -->
        <b-table 
            v-if="secrets.length !== 0 && !loading" 
            striped hover 
            :items="secrets" 
            :fields="secretFields"
            :sort-by="'id'"
            show-empty
            responsive
            stacked="md"
        >
            <template #cell(actions)="row">
                <b-button size="sm" v-if="row.item.status == 'unloaded'" @click="loadSecret(row.item)" class="mr-1">
                    Load value
                </b-button>

                <!-- loader -->
                <div v-else-if="row.item.status === 'loading'">
                    <b-spinner class="o2-bt-spin-sz" type="grow" label="Loading..."></b-spinner> Loading secret...
                </div>

                <!-- loader -->
                <div v-else-if="row.item.status === 'loaded'">
                    {{ row.item.Value }}
                </div>

                <b-button 
                    v-b-modal.update-basil-modal 
                    size="sm"
                    @click="updatingSecretId = row.item.id"
                >
                Update
                </b-button>

            </template>
        </b-table>
        <b-modal id="update-basil-modal"  size="lg" :title="`Update secret ${updatingSecretId} for account ${accountId}`" :hide-footer="true">
            <UpdateBasilForm :accountId="accountId" :secretid="updatingSecretId" />
        </b-modal>
    </div>
</template>
<script>
import mixins from '@/mixins';
import CreateBasilForm from '@/components/CreateBasilForm.vue'
import UpdateBasilForm from '@/components/UpdateBasilForm.vue'
export default {
  name: 'AccountBasilTable',
  mixins: [mixins],
  components: {
      CreateBasilForm,
      UpdateBasilForm
  },
  props: {
      accountId: {
          required: true
      }
  },
  data () {
    return {
        error: '',
        loading: true,
        updatingSecretId: "",
        secrets: [],
        secretFields: [
            {
                "key" : "accountid",
                "sortable" : true
            },
            {
                "key" : "id",
                "label" : "Name",
                "sortable" : true
            },
            {
                "key" : "actions",
                "label" : "Value",
                "sortable" : false
            }
        ],
        secretStatus: {}    // store whether each secret value has been retrieved
    }
  },
  computed : {},
  mounted () {

    // load secrets
    this.automationApiRequest('/basil/' + this.accountId + '/secrets', 'get').then(data => {
        this.secrets = data.Items.map(secret => {
            secret.status = "unloaded"
            return secret
        })
        this.loading = false
    }).catch(err => {
        this.error = this.getErrorString(err)
        this.loading = false
    })
  },
  methods: {
      loadSecret (secret) {
          secret.status = 'loading'
          this.automationApiRequest(`/basil/${this.accountId}/secrets/${secret.id}`).then(data =>{
            secret.Value = data.Value
            secret.status = 'loaded'
          })
      },
  }
}
</script>