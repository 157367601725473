<template>
    <div style="margin: 0 2vw;">

        <!-- alert banner -->
        <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

        <b-tabs lazy align="center" content-class="mt-3">
            <b-tab title="Info" >

                <!-- loader -->
                <div v-if="loadingAccount" class="loading">
                    <b-spinner type="grow" label="Loading..."></b-spinner> Loading account...
                </div>

                <!-- table -->
                <ObjectInfo style="max-width: 70rem; margin: auto;" v-if="account != null" :obj="account" />
            </b-tab>
            <b-tab title="Resources" active>
                <AccountResourceTable :accountId="accountId" />
            </b-tab>
            <b-tab title="Tags">
                <AccountTagTable :accountId="accountId" />
            </b-tab>
            <b-tab title="Automations (Saffron)">
                <SaffronEntityContracts :EntityId="this.accountId" :EntityType="this.saffronEntityType"/>
            </b-tab>
            <b-tab title="Secrets">
                <AccountBasilTable :accountId="accountId" />
            </b-tab>
            <b-tab title="Terraform">
                <AccountGadgetTable v-if="account != null" :accountId="accountId" :accountType="account.type" />
            </b-tab>
            <b-tab title="Onboarding Jobs">
                <AccountPerseusTable :accountId="accountId" />
            </b-tab>
        </b-tabs>

    </div>
</template>

<script>
import mixins from '@/mixins';
import SaffronEntityContracts from '@/components/SaffronEntityContracts.vue'
import AccountResourceTable from '@/components/AccountResourceTable.vue'
import AccountGadgetTable from '@/components/AccountGadgetTable.vue'
import AccountTagTable from '@/components/AccountTagTable.vue'
import AccountBasilTable from '@/components/AccountBasilTable.vue'
import AccountPerseusTable from '@/components/AccountPerseusTable.vue'
import ObjectInfo from '@/components/ObjectInfo.vue'
export default {
  name: 'Account',
  mixins: [mixins],
  components: {
    AccountResourceTable,
    AccountGadgetTable,
    AccountTagTable,
    AccountBasilTable,
    AccountPerseusTable,
    ObjectInfo,
    SaffronEntityContracts
  },
  data() {
    return {

        error: '',
        accountId: this.$route.params.accountid,
        account: null,
        accountInfoItems: [],
        accountInfoFields: [
            {
                "key" : "key",
                "sortable" : true
            },
            {
                "key" : "value",
                "sortable" : true
            }
        ],
        loadingAccount: true,
        saffronEntityType: ''
    }
  },
  mounted () {

    // load account
    this.automationApiRequest('/cacmdb/accounts/' + this.accountId, 'get').then(data => {
        
        // set account attribute
        this.account = data

        // set saffronEntityType based on account type
        if (this.account.type === 'azure'){
            this.saffronEntityType = 'AzureSubscription'
        } else {
            this.saffronEntityType = 'AwsAccount'
        }

        // un-nest JSON by using '.' path syntax
        var dotPathAccount = this.dotPathObject(this.account)
        if (dotPathAccount['aws.managedregions']) {
            dotPathAccount['aws.managedregions'] = dotPathAccount['aws.managedregions'].join(', ')
        }

        // convert '.' path JSON to table row items
        for (var key in dotPathAccount) {
            this.accountInfoItems.push({key:key, value: dotPathAccount[key]})
        }
        this.loadingAccount = false
        
    }).catch(err => {
        this.error = this.getErrorString(err)
        this.loadingAccount = false
    })

  },
  methods: {}
}
</script>

<style scoped lang="scss">

</style>