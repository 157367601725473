<template>
    <div>
        <b-tabs class="main" content-class="mt-3">
            <b-tab title="Info" active>

                <!-- loader -->
                <div v-if="loadingAutomation" class="loading">
                    <b-spinner type="grow" label="Loading..."></b-spinner> Loading automation...
                </div>

                <!-- table -->
                <b-table v-else 
                    striped hover 
                    :items="automationInfoItems"
                    show-empty
                    responsive
                    stacked="md"
                >
                </b-table>
            </b-tab>
            <b-tab title="Logs">
                
                <!-- loader -->
                <div v-if="loadingAutomation" class="loading">
                    <b-spinner type="grow" label="Loading..."></b-spinner> Loading logs...
                </div>

                <!-- no logs message -->
                <p v-if="logs.length === 0 && !loadingAutomation">No logs found.</p>

                <!-- json info -->
                <div class="automation-log-list">
                    <div 
                        class="o2-automation-log"
                        v-for="log in logs"
                        :key="log.key"  
                    >
                        <p>{{ log.key }}</p>
                        <pre class="o2-json-item log-text">{{ log.text }}</pre>
                    </div>
                </div>
            </b-tab>
        </b-tabs>

    </div>
</template>

<script>
import mixins from '@/mixins';
export default {
  name: 'Resource',
  mixins: [mixins],
  data() {
    return {
        automationId: this.$route.params.automationid,
        automation: null,
        logs: {},
        automationInfoItems: [],
        loadingAutomation: true
    }
  },
  mounted () {

    // load automation
    this.automationApiRequest('/cacmdb/automations/' + this.automationId, 'get').then(data => {
        this.automation = data;
        
        // get ssm logs
        var ssm = data.ssm || {};
        var logData = this.dotPathObject(ssm.logs);
        this.logs = [];
        for (var logKey in logData) {
            this.logs.push({
                key: logKey,
                text: logData[logKey] 
            })
        }

        // get other info (besides logs)
        var dotPath = this.dotPathObject(this.automation)
        for (var key in dotPath) {
            if (!key.includes('.logs.')) {
                this.automationInfoItems.push({
                    key: key,
                    value: dotPath[key]
                })
            }
        }

        this.loadingAutomation = false;
    })

  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

.main {
    margin: 0 5vw;
}

.log-text {
    word-wrap: break-word;
    white-space: pre-wrap;
}

</style>