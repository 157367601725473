<template>
    <div style="padding: 0 2rem;">
        <div class="o2-absolute-tl o2-border-item" style="padding: 5px;">
            <router-link :to='`/accounts/${resource.accountid}`'><b-icon font-scale="1" icon="arrow-left-circle" /> Account</router-link>
        </div>
        <b-tabs lazy align="center" content-class="mt-3">
            <b-tab title="Info" style="margin: 0 auto; max-width: 50rem" active>

                <!-- loader -->
                <div v-if="loadingResource" class="loading">
                    <b-spinner type="grow" label="Loading..."></b-spinner> Loading resource...
                </div>

                <!-- json info -->
                <ObjectInfo v-else :obj="resource" />
            </b-tab>
            <b-tab title="Automate (with Saffron)">
                <SaffronEntityContracts :EntityId="this.resourceId" :EntityType="this.EntityType" />
            </b-tab>
            <b-tab v-if="EntityType == 'AwsEc2Instance'" title="SSM Session">
                <InstanceSsmSessionList
                    style="margin: 0 auto; max-width: 80vw;"
                    :resourceId="resource.cloudresourceid"
                    :accountId="resource.accountid"
                    :region="resource.region"
                />
                <div style="text-align: center; margin: 2rem;">
                    <b-button @click="startSsmSession">Start SSM Session <b-icon icon="box-arrow-up-right" /></b-button>
                </div>
            </b-tab>
        </b-tabs>

    </div>
</template>

<script>
import mixins from '@/mixins';
import ObjectInfo from '@/components/ObjectInfo.vue';
import InstanceSsmSessionList from '@/components/InstanceSsmSessionList.vue';
import SaffronEntityContracts from '@/components/SaffronEntityContracts.vue'
export default {
  name: 'Resource',
  mixins: [mixins],
  components: {
      InstanceSsmSessionList,
      ObjectInfo,
      SaffronEntityContracts
  },
  data() {
    return {
        resourceId: this.$route.params.resourceid,
        resource: null,
        EntityType: "",
        loadingResource: true
    }
  },
  mounted () {

    // load account
    this.automationApiRequest('/cacmdb/resources/' + this.resourceId, 'get').then(data => {
        this.resource = data
        this.loadingResource = false
        if (this.resource.type == 'aws-vpc'){
            this.EntityType = 'AwsEc2Vpc'
        } 

        else if (this.resource.type == 'aws-ec2'){
            this.EntityType = 'AwsEc2Instance'
        } 

        else if (this.resource.type == 'aws-rds'){
            this.EntityType = 'AwsRdsInstance'
        } 

        else if (this.resource.type == 'azure-vm'){
            this.EntityType = 'AzureVirtualMachine'
        }
        else if (this.resource.type == 'azure-vnet'){
            this.EntityType = 'AzureVirtualNetwork'
        }
        else if (this.resource.type == 'azure-webapp'){
            this.EntityType = 'AzureWebApp'
        }
        else if (this.resource.type == 'azure-sql'){
            this.EntityType = 'AzureSqlServer'
        }

        else if (this.resource.type == 'aws-vpn-connection'){
            this.EntityType = 'AwsEc2VpnConnection'
        }

        else {
            console.log('error unexpected resource type:', this.resource.type)
        }
    })

  },
  methods: {
      startSsmSession(){
        let routeData = this.$router.resolve({name: 'SSM Session', params: {resourceId: this.resourceId}});
        window.open(routeData.href, '_blank');
      },
      navigateToAutomation (record) {
          record._o2_type = 'automation';
          this.navigateToItem(record);
      }
  }
}
</script>