<template>
  <div style="margin: 0 auto; max-width: 100rem; padding: 0 3rem;">

    <!-- alert banner -->
    <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>
    <b-alert variant="info" v-if="info" show>{{ info }}</b-alert>

    <!-- title text -->
    <div class="tab-title">
      <strong>Saffron {{ contract.ConfigurationType || "Automation" }} Contract</strong>
    </div> 

    <div v-if="contract.EntityType && !hideEntityLink" class="o2-absolute-tl o2-border-item" style="padding: 5px;">
      <router-link :to="EntityPath"><b-icon font-scale="1" icon="arrow-left-circle" /> {{ contract.EntityType }}</router-link>
    </div>

    <!-- loader -->
    <div v-if="loading" class="o2-absolute-br o2-border-item" style="padding: 0.5rem;">
        <b-spinner style="width: 1.5rem; height: 1.5rem;" type="grow" label="Loading contracts..."></b-spinner> {{ loadingMessage }}
    </div>

    <b-tabs content-class="mt-3">
        <b-tab title="Info">
          <b-row>
            <JsonItemEditor 
              v-if="!loading"
              :startObject="contract" 
              :updatableFields="['Parameters', 'Active']"
              buttonText="Edit contract"
              :modalTitle="'Edit ' + configurationType.Name + ' Contract'"
              :key="'editor-' + contractLastUpdated"
              @updateSubmit="updateContract"
            />

            <!-- button for deactivating contract -->
            <b-button 
                v-if="contract.Active && !loading"
                v-b-modal.deactivate-contract 
                variant="warning" pill
                style="margin: 0 0 1rem 1rem;"
            >
                <b-icon icon="power"/> Deactivate contract
            </b-button>
            <b-modal 
              id="deactivate-contract" 
              size="lg" 
              title="Deactivate contract"
              @ok="deactivateContract"
            >
              <form ref="form">
                <b-form-textarea
                  id="textarea"
                  v-model="deactivationNote"
                  placeholder="Why this contract is being deactivated"
                  rows="3"
                  max-rows="6"
                  required
                ></b-form-textarea>
              </form>
            </b-modal>

            <!-- button for reactivating contract -->
            <b-button 
                v-if="!(contract.Active || loading)"
                v-b-modal.reactivate-contract 
                variant="success" pill
                style="margin: 0 0 1rem 1rem;"
            >
                <b-icon icon="power"/> Reactivate contract
            </b-button>
            <b-modal 
              id="reactivate-contract" 
              size="lg" 
              title="Reactivate contract"
              @ok="reactivateContract"
            >
              <form ref="form">
                <b-form-textarea
                  id="textarea"
                  v-model="reactivationNote"
                  placeholder="Why this contract is being reactivated"
                  rows="3"
                  max-rows="6"
                  required
                ></b-form-textarea>
              </form>
            </b-modal>

          </b-row>


          <ObjectInfo v-if="displayContract" :obj="displayContract" :key="contractLastUpdated" />
          <p v-else-if="!loading">Not found.</p>
        </b-tab>
        <b-tab title="Entity">
          <ObjectInfo v-if="entity" :obj="entity" />
        </b-tab>
        <b-tab title="Jobs" active>

            <!-- button for refreshing jobs-->
            <b-button 
              variant="secondary" pill
              style="margin-bottom: 1rem;"
              @click="reloadJobs"
            >
              <b-icon icon="arrow-clockwise" /> Reload jobs
            </b-button>

            <!-- button for creating new job -->
            <b-button 
              v-b-modal.create-job-modal 
              variant="info" pill
              style="margin-bottom: 1rem; margin-left: 1rem;"
            >
              <b-icon icon="plus"/> Run job
            </b-button>

            <!-- modal popup for creating new job -->
            <b-modal 
              id="create-job-modal"  
              size="md" 
              :title="'Saffron Job - ' + contract.ConfigurationType"
              ok-title = "Run"
              @ok="createJob"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                  label="Action"
                  label-for="action-select"
                  invalid-feedback="Selection is required"
                  :state="actionSelectState"
                >
                  <b-form-select
                    id="action-select"
                    v-model="actionSelect"
                    :state="actionSelectState"
                    :options="configurationActions"
                    required
                  ></b-form-select>
                </b-form-group>
                
                <!-- action description -->
                <p v-if="actionSelect !== null">{{ selectedActionDescription }}</p>

              </form>
            </b-modal>

            <!-- existing jobs table -->
            <b-table 
                v-if="jobs.length > 0" 
                striped hover sort-by="DateCreated"
                :sort-desc="true"
                :items="jobs" 
                :fields="jobFields"
                show-empty
                stacked="md"
                small
            >
                <!-- template for link to job -->
                <template #cell(JobId)="data">
                    <router-link :to="`/saffron/jobs/${data.item.JobId}`"><b-icon class="o2-grow" icon="link" /></router-link>
                </template>
            </b-table>

            <!-- no jobs message -->
            <p v-else-if="!loading">No jobs found.</p>
        </b-tab>
    </b-tabs>
  </div>
    
</template>

<script>
import mixins from '@/mixins';
import ObjectInfo from '@/components/ObjectInfo.vue';
import JsonItemEditor from '@/components/JsonItemEditor.vue';
export default {
  name: 'SaffronContract',
  mixins: [mixins],
  components: {
      ObjectInfo,
      JsonItemEditor
  },
  props: {
    contractId: {
      type: String,
      required: true
    }, 
    hideEntityLink: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    EntityPath: function (){
      if (['AwsAccount', 'AzureSubscription'].includes(this.contract.EntityType)){
        return `/accounts/${this.contract.EntityId}`
      } else {
        return `/resources/${this.contract.EntityId}`
      }
    }
  },
  data () {
    return {

      // string to display on info or error banner
      error: '',
      info: '',

      // loading
      loading: true,
      loadingMessage:'Loading contract...',

      // action select value from new job modal
      actionSelect: null,
      actionSelectState: null,
      selectedActionDescription: '',

      // contract data
      contract: {},
      displayContract: {},
      contractLastUpdated: null,

      // entity data
      entity: null,

      // type of contract configuration
      configurationType: {},
      configurationActions: [],

      // deactivation data
      deactivationNote: '',
      reactivationNote: '',

      // contract jobs
      jobs: [],
      jobFields: [
        {
            "key" : "JobId",
            "sortable" : false,
            "label" : "Job"
        },
        {
            "key" : "DateCreated",
            "sortable" : true
        },
        {
            "key" : "Creator",
            "sortable" : true
        },
        {
            "key" : "Status",
            "sortable" : true
        },
        {
            "key" : "Action",
            "sortable" : true
        }
      ]
    }
  },
  watch: {
    // whenever question changes, this function will run
    actionSelect: function () {
      for (const [action, data] of Object.entries(this.configurationType.ValidActions)) {
        if (action === this.actionSelect) 

          // update form description
          if (data.Description) {
            this.selectedActionDescription = data.Description
          } else {
            this.selectedActionDescription = "No description"
          }
      }
    }
  },
  mounted () {
    
    // load contract and jobs
    this.reloadContract()
    this.reloadJobs()

  },
  methods: {
    reloadEntity() {

      this.loading = true
      this.loadingMessage = 'Loading entity...'

      let requestUrl
      if (this.contract.EntityType === 'AwsAccount'){
        requestUrl = `/cacmdb/accounts/${this.contract.EntityId}`
      } else {
        requestUrl = `/cacmdb/resources/${this.contract.EntityId}`
      }

      this.automationApiRequest(requestUrl, 'get').then(entity => this.entity = entity)

    },
    reloadContract() {

      // request contract
      this.loading = 'contracts'
      this.loadingMessage = 'Loading contract...'
      this.automationApiRequest(
        '/saffron/contracts/' + this.contractId,
        'get'
      ).then(contract => {
        this.contract = contract
        this.loading = (this.loading == 'contracts' ? false : this.loading)
        this.reloadEntity()
        this.loadConfigurationType()
      }).catch(err => {
        this.loading = false
        this.error = this.getErrorString(err)
      })

    },
    reloadJobs() {

      // request jobs
      this.loading = 'jobs'
      this.loadingMessage = 'Loading jobs...'
      this.automationApiRequest(
        '/saffron/jobs?ContractId=' + this.contractId,
        'get'
      ).then(response => {
        this.jobs = response.Items
        this.loading = (this.loading == 'jobs' ? false : this.loading)
      }).catch(err => {
        this.error = this.getErrorString(err)
        this.loading = false
      })

    },
    navigateToJob(job) {

      this.$router.push({ path: `/saffron/jobs/${job.JobId}` })
      this.$router.go(1)

    },
    loadConfigurationType() {

      // request contract
      this.loading = 'configurationType'
      this.loadingMessage = 'Loading configuration data...'

      // request configuration type
      this.automationApiRequest(
        `/saffron/configurations?Name=${this.contract.ConfigurationType}&EntityType=${this.contract.EntityType}`,
        'get'
      ).then(response => {
        
        this.configurationType = response.Items[0]
        this.displayContract = this.deepMerge(this.configurationType, this.contract)

        // do this to trigger re render of contract info
        this.contractLastUpdated = new Date().getTime()
        this.configurationActions = Object.keys(this.configurationType.ValidActions)
        this.configurationActions.sort();

        this.loading = (this.loading == 'configurationType' ? false : this.loading)

      }).catch(err => {
        this.error = this.getErrorString(err)
        this.loadingContract = false
      })

    },
    createJob() {
      if (!this.actionSelect){
        this.actionSelectState = this.$refs.form.checkValidity();
      } else {
        this.loading = true;
        this.loadingMessage = 'Creating job...'
        this.automationApiRequest(
          `/saffron/jobs`,
          'post',
          {
            ContractId: this.contractId,
            Action: this.actionSelect
          }
        ).then(job => {
          this.loading = false
          this.jobs.push(job)
          this.info = "Created job " + job.JobId
        }).catch(err => {
          this.loading = false
          this.error = this.getErrorString(err)
        })
      }
    },
    updateContract(update) {
      this.error = ''
      this.info = ''
      this.loading = true
      this.loadingMessage = "Updating contract..."
      this.automationApiRequest(
        `/saffron/contracts/${this.contractId}`,
        'put',
        update
      ).then(() => {

        this.info = 'Contract successfully updated'
        this.error = ''
        this.loading = false
        this.reloadContract()
        this.reactivationNote = ''
        this.deactivationNote = ''

      }).catch(err =>{

        this.loading = false
        this.error = this.getErrorString(err)
        this.info = ''

      })
    },
    deactivateContract(){
      if (this.deactivationNote.split(" ").length < 3){
        this.error = 'Please include a note (>3 words) indicating why this contract is deactivated.'
        return
      }
      this.updateContract({
        "Active" : false,
        "Notes" : {
          "Deactivation" : {
            "Reason" : this.deactivationNote
          }
        }
      })
    },
    reactivateContract(){
      if (this.reactivationNote.split(" ").length < 3){
        this.error = 'Please include a note (>3 words) indicating why this contract is reactivated.'
        return
      }
      this.updateContract({
        "Active" : true,
        "Notes" : {
          "Reactivation" : {
            "Reason" : this.reactivationNote
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.tab-title {
    margin-bottom: 1rem;
    width: auto;
}
</style>