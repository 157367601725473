<template>
    <div style="margin: 0 auto; max-width: 100rem">

        <!-- alert banner -->
        <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

        <!-- loader -->
        <div v-if="loading" class="loading">
            <b-spinner class="o2-bt-spin-sz" type="grow" label="Loading..."></b-spinner> Loading items...
        </div>

        <b-row>
            <b-button 
                v-b-modal.create-tag-modal 
                style="margin-bottom:1rem; margin-right:1rem;"
                variant="info" pill
            >
                <b-icon icon="plus"/> Create
            </b-button>
            <b-modal id="create-tag-modal"  size="lg" title="New Tag" :hide-footer="true">
                <CreateTagForm :accountId="accountId" />
            </b-modal>
            <b-button 
                pill @click="loadTags"
                style="margin-bottom:1rem;"
            >
                <b-icon icon="arrow-clockwise" /> Reload
            </b-button>
        </b-row>
        <b-row>

            <!-- no resources message -->
            <p v-if="tags.length === 0 && !loading">No tags found.</p>

            <!-- table -->
            <b-table 
                v-if="tags.length !== 0 && !loading" 
                class="o2-clickable-table"
                striped hover 
                @row-dblclicked="navigateToTag" 
                :items="tags" 
                :fields="tagFields"
                :sort-by="'alias'"
                show-empty
                responsive
                bordered
                stacked="md"
            >
                <!-- template for link to resource -->
                <template #cell(alias)="data">
                    <router-link :to="`/tags/${data.item.id}`">{{ data.item.alias }}</router-link>
                </template>
            </b-table>
        </b-row>
    </div>
</template>
<script>
import mixins from '@/mixins';
import CreateTagForm from '@/components/CreateTagForm.vue'
export default {
  name: 'AccountTagTable',
  mixins: [mixins],
  components: {
      CreateTagForm
  },
  props: {
      accountId: {
          required: true
      }
  },
  data () {
    return {
        error: '',
        loading: true,
        tags: [],
        tagFields: [
            {
                "key" : "alias",
                "sortable" : true
            },
            {
                "key" : "accountid",
                "sortable" : true
            },
            {
                "key" : "bluematador",
                "sortable" : true
            },
            {
                "key" : "rapid7",
                "sortable" : true
            },
            {
                "key" : "system-manager",
                "sortable" : true
            },
            {
                "key" : "osconfig",
                "sortable" : true
            },
            {
                "key" : "trend",
                "sortable" : true
            },
            {
                "key" : "cloudwatch-agent",
                "sortable" : true
            },
            {
                "key" : "cloudwatch-alarm",
                "sortable" : true
            },
            {
                "key" : "bigfix",
                "sortable" : true
            },
            {
                "key" : "devo",
                "sortable" : true
            },
            {
                "key" : "sumo",
                "sortable" : true
            },
            {
                "key" : "cpm",
                "sortable" : true
            },
            {
                "key" : "resourcetype",
                "label" : "resourceType",
                "sortable" : true
            },
            {
                "key" : "services.cpm.policies",
                "label" : "CPM Policy IDs",
                "sortable" : true
            }
        ],
    }
  },
  computed : {},
  mounted () {

      // request tags from CACMDB
      this.loadTags()

  },
  methods: {
      navigateToTag (record) {
        this.$router.push({ path: `/tags/${record.id}` })
        this.$router.go(1)
      },
      loadTags (){
        this.loading = true
        // load tags
        this.automationApiRequest('/cacmdb/tags?accountid=' + this.accountId, 'get').then(data => {
            this.tags = data.Items.map(tag => {
                for (var service in tag.services) {
                    if (tag.services[service].automated) {
                        tag[service] = 'automated'
                    } else if (tag.services[service].requested && !tag[service]) {
                        tag[service] = 'requested'
                    }
                }
                return tag
            })
            this.tags = this.tags.map(tag => this.dotPathObject(tag))
            this.loading = false
        }).catch(err => {
            this.error = this.getErrorString(err)
            this.loading = false
        })
      }
  }
}
</script>

<style scoped lang="scss">

</style>