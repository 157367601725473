<template>
  <div class="container">

      <!-- alert banner -->
      <b-alert variant="info" v-if="info" show>{{ info }}</b-alert>
      <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>


      <!-- searchbar -->
      <input class="searchbar" placeholder="Filter accounts..." v-model="searchInput" @keyup="search">

      <!-- account list -->
      <div v-if="accounts.length == 0" class="loading">
        <b-spinner type="grow" label="Loading..."></b-spinner> Loading accounts...
      </div>
      <b-container fluid="lg" v-else >
        <b-row>
          <div 
            :key="account.id"  
            v-for="account in displayedAccounts" class="o2-grid-item"
            @dblclick="navigateToItem(account)"
          >
              <span class="o2-grid-item-line">{{ account.connectria.shortname || account.accountid }}</span>
              <span class="o2-grid-item-line">{{ account.aws.alias || account.accountid }}</span>  
          </div>
        </b-row>
      </b-container>

  </div>
</template>
<script>
import mixins from '@/mixins';
export default {
  name: 'Search',
  mixins: [mixins],
  data () {
      return {

        // error or info banners
        error: '',
        info: '',

          // value input into searchbar
          searchInput: '',

          // list of accounts; start null to show loader
          accounts: [],

          // accounts to display
          displayedAccounts: []

      }
  },
  mounted () {
    // load folder contents
    this.loadAccounts()
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  computed: {},
  methods: {
    async loadAccounts() {

      // load accounts
      this.fullAutomationApiResults('/cacmdb/accounts').then(items => {

        // add default values
        this.accounts = items.map(account => this.addDefaultAccountValues(account))

        // update displayed accounts
        this.displayedAccounts = items

      }).catch(err => {
        this.error = this.getErrorString(err)
      })
    },
    search() {
      this.displayedAccounts = this.accounts.filter(account => this.recursiveValues(account)
        .toString().toLowerCase().includes(
          this.searchInput.toLowerCase()
        ))
    }
  }
}
</script>

<style scoped lang="scss">
.searchbar {
  width: 100%;
  padding: 10px 18px;
  font-size: 18px;
  margin-bottom: 16px;
  &:focus {
    outline: 0;
  }
}

</style>