<template>
  <div>

    <!-- button for editing contract -->
    <b-button 
        v-b-modal.edit-contract-modal 
        variant="info" pill
        style="margin-bottom: 1rem;"
    >
        <b-icon icon="pencil"/> {{ buttonText }}
    </b-button>

    <!-- modal popup for creating new job -->
    <b-modal 
        id="edit-contract-modal"  
        size="lg" 
        :title="modalTitle"
        ok-title = "Update"
        @ok="emitUpdate"
        @hidden="resetText"
    >
        <p>To delete an attribute, assign it the value of null.</p>

        <!-- alert banner -->
        <b-alert variant="danger" v-if="!isValidJson" show>Invalid JSON</b-alert>

        <b-form-textarea
            id="textarea-auto-height"
            v-model="text"
            rows="3"
            max-rows="100"
            @change="formatJson"
        ></b-form-textarea>
        
        <!-- list updatable fields -->
        <p style="margin-top: 1rem;" v-if="updatableFields"> Updatable fields: {{ updatableFields.join(', ') }}</p>
    </b-modal>
  </div>
</template>

<script>
import mixins from '@/mixins';
export default {
  name: 'JsonItemEditor',
  mixins: [mixins],
  props: {
      startObject: {
          default: {}
      },
      modalTitle: {
          default: 'Edit'
      },
      buttonText: {
          default: 'Edit'
      },
      updatableFields:{}
  },
  data () {
    return {
        text: '{}'
    }
  },
  computed : {
      isValidJson: function () {
        try {
            JSON.parse(this.text);
        } catch (e) {
            return false;
        }
        return true;
      },
      newObject: function () {
        try {
            return JSON.parse(this.text);
        } catch (e) {
            return null;
        }
      },
      updatePayload: function (){
          if (this.newObject === null){
              return null
          } else {
              return Object.fromEntries(
                Object.entries(this.newObject)
                .filter(([key]) => this.updatableFields.includes(key))
            )
          }
      }
  },
  mounted () {
    this.resetText()
  },
  methods: {
      emitUpdate() {
          this.$emit('updateSubmit', this.updatePayload)
      },
      noUpdateMethod() {
          console.log('No update method defined')
      },
      formatJson() {
        try {
            this.text = JSON.stringify(JSON.parse(this.text), null, '\t')
        } catch (e) {
            return;
        }
      },
      resetText(){
          this.text = JSON.stringify(this.startObject, null, '\t')
      }
  }
}
</script>
