<template>
  <div id="app">
    <img class="logo" alt="Connectria" src="@/assets/hero.svg">

    <div v-if="signedIn===false" id="sign-in-container">
      <h1>Welcome to o2</h1> 
      <b-button id="sign-in-button" variant="info" @click="signIn">Sign in with Cognito</b-button>
    </div>
    <div v-if="signedIn===true">
      <!-- sidebar -->
      <SideBar />

      <!-- nav -->
      <div id="nav">
        <router-link to="/">Search</router-link> |
        <router-link to="/onboard">Onboard</router-link> |
        <router-link to="/accounts">Accounts</router-link> |
        <router-link to="/peppermint">Peppermint</router-link> |
        <router-link to="/saffron">Saffron</router-link> |
        <router-link to="/claude">ClAuDE</router-link> |
        <router-link to="/reports">Reports</router-link> |
        <router-link to="/gadget">Gadget</router-link> |
        <a v-b-toggle.main-sidebar>Menu</a>
      </div>
      <router-view/>
    </div>
  </div>
</template>

<script>
import { Auth, Hub } from "aws-amplify";
import SideBar from '@/components/SideBar.vue'

export default {
  name: 'App',
  components: {
    SideBar
  },
  data() {
    return {
      signedIn: null,
      username: String
    };
  },
  methods: {
    signIn: () => {
      Auth.federatedSignIn(); // Cognito is the default provider
    }
  },
  beforeCreate() {

    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log(`Auth event: ${event}`);
      switch (event) {
        case "signIn":
          this.signedIn = true;
          this.username = data.username;
          break;
        case "signOut":
          this.signedIn = false;
          this.username = null;
          break;
      }
    });
    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log("Username: " + user.username);
        console.log("Refreshing access and id tokens");
        Auth.currentSession()
          .catch(err => console.log("Error refreshing tokens: " + err));
        this.signedIn = true;
        this.username = user.username;
      })
      .catch(err => {
        console.log("Auth.currentAuthenticatedUser(): " + err)
        this.signedIn = false;
      }
      );
  }
}

</script>

<style lang="scss">

* {
  box-sizing: border-box;
}

:root {
  --amplify-primary-color: #1765a5;
    --background-color: black;
    --text-color: white;   
}

.loading {
  width: 100%;
  text-align: center;
}

.o2-flex-container {
  margin: auto auto;
  max-width: 90rem;
  justify-content: center;
  display: flex;
}

.logo {
  display: block;
  margin: 3rem auto 1rem auto;
  height: 13vh;
  padding-left: 2%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding-bottom: 30px;
  text-align: center;
  margin: 0 auto;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: rgb(10, 147, 189);
    }
  }
}

.default-button {
  margin: 0 auto;
  background-color: white;
  border-radius: 0.8rem;
  border-width: 0.08rem;
  border-style: solid;
  border-color: lightgray;
}

#amplify-auth {
  --container-height: 60vh;
}

#sign-in-container {
  text-align: center;
  width: 100%;
  margin: 5vh auto 0 auto;
}

.o2-grow { transition: all .1s ease-in-out; }
.o2-grow:hover { transform: scale(1.5); }

.o2-main {
    margin: 0 5vw 5rem 5vw;
}

.o2-absolute-center {
  position:absolute; 
  left:50%; 
  top:50%; 
  transform: translate(-50%, -50%);
}

.o2-absolute-tl {
  position:absolute; 
  left: 1rem;; 
  top: 1rem; 
}

.o2-absolute-br {
  position:fixed;
  bottom: 2rem;
  right: 5rem;
}

// correct size for o2 bt spinner
.o2-bt-spin-sz {
  width: 1.5rem; 
  height: 1.5rem;
}

.o2-border-item {
  background-color: white;
  border-style: solid;
  border-width: 0.1rem;
  border-radius: 0.3rem;
  border-color: rgba(87, 87, 87, 0.726);
}

.o2-log-container {
    overflow-wrap: normal;
}

.o2-log-text {
    word-wrap: break-word;
    white-space: pre-wrap;
}

.o2-json-item {
  padding: 1rem;
  background-color: rgb(235, 232, 232);
  border-radius: 1rem;
  white-space: pre;
  overflow-wrap: break-word;
}

.o2-item-pane {
  padding-top: 1.5rem;
}


.o2-item-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  border: none;
}

.o2-grid-item {
  cursor: pointer;
  margin-bottom: 0.3rem;
  width: 20rem;
  margin: 1rem;
  align-items: center;
  justify-content: space-between;
  animation-duration: 0.3s;
  padding: 1.1rem;
  background-color: white;
  border-style: solid;
  border-width: 0.1rem;
  border-radius: 0.3rem;
  border-color: rgba(87, 87, 87, 0.726);
  overflow:hidden; 
  white-space:nowrap;
  
}

.o2-grid-item:hover {
  background-color: rgb(194, 233, 243);
}

.o2-grid-item-line {
  display: block;
  overflow:hidden; 
  text-overflow: ellipsis;
}

.o2-clickable-table {
  cursor: pointer;
}

.o2-checkbox-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    border: none;
}

.o2-automation-log {
    margin: 2rem 0;
    overflow-wrap: normal;
}

</style>

