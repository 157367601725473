<template>

  <div>
    <b-sidebar id="main-sidebar" shadow>
      <b-container class="sidebar-contents">
        <h2 id="username-display"><b-icon-person></b-icon-person> {{ username }}</h2>
          <b-nav vertical>
            <b-nav-item class="sidebar-row">
              <b-button id="generate-token-button" type="button" @click="logAccessToken">console log token</b-button>
            </b-nav-item>
            <b-nav-item class="sidebar-row">
              <b-button id="sign-out-button" variant="danger" type="button" @click="handleSignOut">sign out</b-button>
            </b-nav-item>
          </b-nav>
      </b-container>
    </b-sidebar>
  </div>
</template>

<script>
import { Hub } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import jwt_decode from "jwt-decode";

export default {
  name: 'UserBanner',
  data (){
    return  {
        username: ''
    }
  },
  async created() {
    this.getUserName();
    
    // listen for sign in events and update current user data
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
            this.$store.commit('alarmRecentSignIn');
            this.getUserName();
            break;
        case 'signUp':
            console.log('user signed up');
            break;
        case 'signOut':
            console.log('user signed out');
            break;
        case 'signIn_failure':
            console.log('user sign in failed');
            break;
        case 'configured':
            console.log('the Auth module is configured');
      }
    });
    
  },
  methods:{
    async getUserName() {
      const jwt = await Auth.currentSession().then(
        data => jwt_decode(
          data.getAccessToken().getJwtToken()
        ))
      this.username = jwt.username;
    },
    async logAccessToken(){
      const tok = await Auth.currentSession().then(data => data.getAccessToken())
      console.log(tok)
      console.log(process.env)
    },
    async handleSignOut() {
      Auth.signOut()
        .catch(err => console.log(err));
  },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

#username-display {
  text-transform: lowercase;
}

#main-sidebar {
  background-color: white;
}

.sidebar-contents {
  text-align: center;
}

.sidebar-row {
  margin-top: 1rem;
}

</style>
