<template>
  <div class="main">

    <!-- alert banner -->
    <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>
    <div>
        <b-form @submit="onSubmit" style="padding-left: 5rem;" inline>
            <label class="mr-sm-2" for="ticket-id-input">Ticket ID</label>
            <b-form-input
                id="ticket-id-input"
                type="number"
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="form.ticketId"
            ></b-form-input>

            <b-button @click="analyzeTicket" variant="primary">Analyze</b-button>
        </b-form>
    </div>
    <div v-if="!loadingAnalysis">
        <AssignmentGraph style="height: 60rem;" :rankings="assignmentRankings"/>
    </div>
    <div v-else>
        <transition name="fade">
            <div :key="`loadingMessage-${loadingMessage}`" class="center-text">
                <b-spinner type="grow" label="Loading..."></b-spinner> {{ loadingMessage }}
            </div>
        </transition>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import mixins from '@/mixins';
import AssignmentGraph from '@/components/graphs/AssignmentGraph.vue'
export default {
  name: 'Claude',
  mixins: [mixins],
  components: {
    AssignmentGraph
  },
  computed: {
      loadingMessage () {
          return this.loadingMessages[this.loadingMessageIx%this.loadingMessages.length]
      }
  },
  data () {
      return {
        error: '',
        form: {
            ticketId: null
        },
        loadingAnalysis: false,
        assignmentRankings: {},
        loadingMessages: [
            "Chugging coffee...",
            "Warming up...",
            "Stretching...",
            "Working on it...",
            "Crunching numbers...",
            "Computing scores..."
        ],
        loadingMessageIx: 0,
        loadingTimer : null
    }
  },
  beforeDestroy () {
    
    // clear the timer!
    clearInterval(this.loadingTimer)

  },
  methods :{
        onSubmit(event) {
            event.preventDefault()
        },
        updateLoadingMessage() {

            // increment loading messages
            this.loadingMessageIx++;

            // // random loading message
            // this.loadingMessageIx = Math.floor(Math.random()*this.loadingMessages.length);
        },
        putLoadingMessages(isLoading) {
            clearInterval(this.loadingTimer)
            if (isLoading) {
                this.timer = setInterval(this.updateLoadingMessage, 3000);
            }
        },
        loadingStatus(isLoading) {
            this.putLoadingMessages(isLoading)
            this.loadingAnalysis = isLoading;
        },
        analyzeTicket () {
            this.loadingStatus(true)
            this.automationApiRequest(
                '/paprika/tickets/analysis/assignment',
                'post',
                {
                    TicketId: this.form.ticketId
                }
            ).then(res => {
                this.assignmentRankings = res.Rankings
                this.loadingStatus(false)
            }).catch(err => {
                this.assignmentRankings = {}
                this.error = this.getErrorString(err)
                this.loadingStatus(false)
            })
        }
    }
}
</script>

<style scoped>
.main {
    margin: 5rem auto;
    max-width: 100rem;
}

.center-text {
    width: 100%;
    text-align: center;
}

.fade-enter-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>