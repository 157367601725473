<template>
  <div class="container">
    <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>
    <div v-if="loadingAws || loadingAzure" style="margin-bottom: 1rem;" class="loading">
        <b-spinner label="Loading..."></b-spinner> Loading accounts...
    </div>
    <b-card no-body>
        <b-tabs card>
        <b-tab title="azure" active>
            <b-card-text>
              <b-table 
                striped hover 
                :fields="fields" 
                :sort-by.sync="sortBy" 
                :sort-desc.sync="sortDesc" 
                :items="azureData"
                show-empty
                responsive
                stacked="md"
              ></b-table>
            </b-card-text>
        </b-tab>
        <b-tab title="aws">
            <b-card-text>
              <b-table 
                striped hover 
                :fields="fields" 
                :sort-by.sync="sortBy" 
                :sort-desc.sync="sortDesc" 
                :items="awsData"
                show-empty
                responsive
                stacked="md"
              ></b-table>
            </b-card-text>
        </b-tab>
        </b-tabs>
    </b-card>
  </div>
</template>

<script>
import mixins from '@/mixins';
export default {
    mixins: [mixins],
    data() {
      return {
        sortBy: 'billable',
        error: "",
        loadingAws: true,
        loadingAzure: true,
        fields: [
            { key: 'account_id', sortable: true },
            { key: 'billable', sortable: true },
            { key: 'spend', sortable: true },
            { key: 'shortname', sortable: true },
            { key: 'type', sortable: true }
        ],
        azureData: [
        ],
        awsData: [
        ]
      }
    },
  mounted () {
    this.automationApiRequest(
        '/bmbilling/azure',
        'get'
    ).then(
        azureData => {
            this.azureData = azureData
            this.loadingAzure = false
        }
    ).catch(err => {
        this.error = this.getErrorString(err)
      })
    this.automationApiRequest(
        '/bmbilling/aws',
        'get'
    ).then(
        awsData => {
            this.awsData = awsData
            this.loadingAws = false
        }
    ).catch(err => {
        this.error = this.getErrorString(err)
      })
  },
  }
</script>