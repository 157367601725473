<template>
    <div>

        <!-- alert banner -->
        <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

        <!-- loader -->
        <div v-if="loading" class="loading">
            <b-spinner class="o2-bt-spin-sz" type="grow" label="Loading..."></b-spinner> Loading resources...
        </div>

        <!-- no resources message -->
        <p v-if="resources.length === 0 && !loading">No resources found.</p>

        <!-- table -->
        <b-table 
            v-if="resources.length !== 0 && !loading" 
            striped hover 
            :items="resources" 
            :fields="resourceFields"
            :sort-by="'created'"
            :sort-desc="true"
            show-empty
            responsive
            bordered
            stacked="md"
        >
            <!-- template for link to resource -->
            <template v-slot:cell(cloudresourceid)="data">
                <router-link :to="`/resources/${data.item.id}`">{{ data.item.cloudresourceid }}</router-link>
            </template>
        </b-table>

    </div>
</template>
<script>
import mixins from '@/mixins';
export default {
  name: 'AccountResourceTable',
  mixins: [mixins],
  props: {
      accountId: {
          required: true
      }
  },
  data () {
    return {
        error: '',
        loading: true,
        resources: [],
        resourceFields: [
            {
                "key" : "cloudresourceid",
                "sortable" : true
            },
            {
                "key" : "name",
                "sortable" : true
            },
            {
                "key" : "deviceid",
                "sortable" : true
            },
            {
                "key" : "created",
                "sortable" : true
            },
            {
                "key" : "tag.value",
                "sortable" : true
            },
            {
                "key" : "region",
                "sortable" : true
            },
            {
                "key" : "solarwinds.nodeid",
                "sortable" : true
            },
            {
                "key" : "type",
                "sortable" : true
            }
        ],
    }
  },
  computed : {},
  mounted () {

    // load resources
    this.fullAutomationApiResults(
        '/cacmdb/resources',
        {
            exists: true,
            accountid: this.accountId
        }
    ).then(items => {
        this.resources = items.map(resource => this.dotPathObject(resource))
        this.loading = false
    }).catch(err => {
        this.error = this.getErrorString(err)
        this.loading = false
    })

  },
  methods: {
      navigateToResource (record) {
          record._o2_type = 'resource';
          this.navigateToItem(record);
      }
  }
}
</script>