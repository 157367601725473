<template>
  <div class="main">

    <!-- alert banner -->
    <b-alert variant="info" v-if="info" show>{{ info }}</b-alert>
    <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

    <b-form @submit="onSubmit" v-if="!secretCreatedSuccess">
      <b-form-group
        label="Name"
        label-for="name-input"
        description="The Name; e.g. api-key-(service): myName"
      >
        <b-form-input
          id="name-input"
          v-model="form.name"
          placeholder="myName..."
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Value"
        label-for="value-input"
        description="The value; e.g. ahoihlirewquh: myValue"
      >
        <b-form-input
          id="value-input"
          v-model="form.value"
          placeholder="myValue..."
          required
        ></b-form-input>
      </b-form-group>
      
      <br>
      
      <div>
        <b-button v-if="!isSubmitted" pill type="submit" variant="info">Create</b-button>
        <div v-else class="loading">
          <b-spinner type="grow" label="Loading..."></b-spinner> Creating Secret...
        </div>
      </div>
    </b-form>
    <p v-else><b-icon icon="check-square" variant="success"></b-icon> Successfully created <code>{{ this.form.value }}</code> secret for account <code>{{ this.accountId }}</code></p>
  </div>
</template>

<script>
import mixins from '@/mixins';
export default {
  mixins: [mixins],
  props: {
    accountId: String,
  },
  data() {
    return {
      info: "",
      error: "",
      form: {name:"", value:""},
      isSubmitted: false,
      secretCreatedSuccess: false,
    };
  },
computed: {
},
  methods: {
    onSubmit(event) {

      event.preventDefault();

      this.isSubmitted = true;

      let dataBody = {
        Value: this.form.value,
      };

      this.error = ""
      this.automationApiRequest(
        '/basil/' + this.accountId + '/secrets/' + this.form.name,
        'post',
        dataBody
      ).then(() => {
        this.secretCreatedSuccess = true;
      }).catch(err => {
        this.isSubmitted = false
        this.error = this.getErrorString(err)
      })
    },
  }
};
</script>
