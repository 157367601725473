<template>
    <b-container fluid="lg">

        <!-- alert banner -->
        <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>
        <b-card no-body>
          <b-tabs content-class="mt-3" card>
              <b-tab title="SaffronSystem" active>

                  <!-- loader -->
                  <div v-if="loading" class="loading" style="margin-bottom: 1rem;">
                      <b-spinner type="grow" label="Loading..."></b-spinner> {{ loadingMessage }}
                  </div>

                  <!-- reload button -->
                  <b-row>

                  </b-row>


                  <!-- User Interface controls -->
                  <b-row style="margin-bottom: 1rem;">

                  <b-col lg="6" class="my-1">
                      <b-form-group
                      label="Filter"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                      >
                      <b-input-group size="sm">
                          <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Type to Search"
                          ></b-form-input>

                          <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                          </b-input-group-append>
                      </b-input-group>
                      </b-form-group>
                  </b-col>

                  <b-col lg="6" class="my-1">
                      <b-form-group
                      v-model="sortDirection"
                      label="Filter On"
                      description="Leave all unchecked to filter on all data"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                      v-slot="{ ariaDescribedby }"
                      >
                      <b-form-checkbox-group
                          v-model="filterOn"
                          :aria-describedby="ariaDescribedby"
                          class="mt-1"
                      >
                          <b-form-checkbox value="Creator">Creator</b-form-checkbox>
                          <b-form-checkbox value="Status">Status</b-form-checkbox>
                          <b-form-checkbox value="ConfigurationType">Configuration Type</b-form-checkbox>
                      </b-form-checkbox-group>
                      </b-form-group>
                  </b-col>

                  <b-col sm="5" md="6" class="my-1">
                      <b-form-group
                      label="Per page"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                      >
                      <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                      ></b-form-select>
                      </b-form-group>
                  </b-col>

                  <b-col sm="7" md="6" class="my-1">
                      <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                      ></b-pagination>
                  </b-col>
                  </b-row>

                  <b-row>
                    <b-button 
                        pill @click="reloadContracts"
                        style="margin-bottom:1rem; margin-left: 4vw;"
                    >
                        <b-icon icon="arrow-clockwise" /> Reload
                    </b-button>
                  </b-row>


                  <!-- table -->
                  <b-table 
                      striped hover 
                      :items="contracts" 
                      :fields="contractFields"

                      :current-page="currentPage"
                      :per-page="perPage"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      stacked="md"
                      show-empty
                      small
                      @filtered="onFiltered"
                  >

                      <!-- template for link to resource -->
                      <template #cell(ContractId)="data">
                          <b-button pill variant="outline-secondary" size="sm" @click="showManageModal(data.item, data.index, $data.target)" class="mr-1">
                            Manage
                          </b-button>
                      </template>

                      <template v-slot:cell(EntityId)="data">
                          <router-link :to="`/resources/${data.item.EntityId}`">{{ data.item.EntityId }}</router-link>
                      </template>

                  </b-table>

                  <!-- ManageModal modal -->
                  <b-modal 
                    :id="manageModal.id" 
                    :title="manageModal.title"
                    size="xl" hide-footer
                    @hide="resetManageModal"
                  >
                    <SaffronContract :contractId="manageModal.ContractId" />
                    <template #modal-title>
                          <router-link :to="`/saffron/contracts/${manageModal.ContractId}`"><b-icon class="o2-grow" icon="link" /></router-link>
                          {{ manageModal.title }} 
                    </template>
                  </b-modal>

              </b-tab>
          </b-tabs>
        </b-card>
    </b-container>
</template>

<script>
import SaffronContract from '@/components/SaffronContract.vue'
import mixins from '@/mixins';
export default {
  name: 'Saffron',
  mixins: [mixins],
  components: {
    SaffronContract
  },
  data() {
    return {
        error: '',
        loading: true,
        loadingMessage: '',
        contracts: [],
        contractFields: [
            {
                key: 'ContractId',
                label: 'Contract',
                sortable: false
            },
            {
                key: 'ConfigurationType',
                sortable: true
            },
            {
                key: 'LastChecked',
                sortable: true
            },
            {
                key: 'Creator',
                sortable: true
            },
            {
                key: 'Status',
                sortable: true
            },
            {
                key: 'EntityId',
                label: 'Resource',
                sortable: false
            }
        ],

        // table filter stuff
        totalRows: 1,
        currentPage: 1,
        perPage: 30,
        pageOptions: [15, 30, 50, 100, { value: 10000, text: "Show a lot" }],
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],

        // management modal for contract
        manageModal: {
          id: 'manage-modal',
          title: '',
          ContractId: ''
        }
    }
  },
  computed: {},
  mounted () {

    this.reloadContracts()

  },
  methods: {
    reloadContracts() {
      // load contracts
      this.loadingMessage = 'Loading contracts...'
      this.loading = true
      this.automationApiRequest('/saffron/contracts?EntityType=SaffronSystem', 'get').then(response => {
          this.contracts = response.Items;
          this.loading = false
          
          // Set the initial number of items
          this.totalRows = this.contracts.length
      }).catch(err => {
          this.error = this.getErrorString(err)
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showManageModal(item, index, button) {
      this.manageModal.title = `${item.ConfigurationType} Contract`
      this.manageModal.ContractId = item.ContractId
      this.$root.$emit('bv::show::modal', this.manageModal.id, button)
    },
    resetManageModal() {
      this.manageModal.title = ''
      this.manageModal.content = ''
    }
  }
}
</script>
