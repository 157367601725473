<template>
    <div style="margin: 0 auto; max-width:70rem">

        <b-tabs class="o2-main" content-class="mt-3">
            <b-tab title="Info" active>
                
                <!-- alert banner -->
                <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>
                
                <b-button style="margin:10px"  @click="deleteTags()" variant="danger" size="sm">delete</b-button>

                <JsonItemEditor 
                    :startObject="tag" 
                    :updatableFields="['services']"
                    buttonText="Edit tag"
                    :modalTitle="'Edit ' + tag.alias + ' Tag'"
                    :key="'editor-' + tagLastUpdated"
                    @updateSubmit="updateTag"
                />

                <!-- loader -->
                <div v-if="loading" class="o2-absolute-br o2-border-item" style="padding: 0.5rem;">
                    <b-spinner class="o2-bt-spin-sz" type="grow" label="Loading..."></b-spinner> Loading tag...
                </div>

                <!-- json info -->
                <ObjectInfo :obj="tag" :key="'ObjInfo-' + tagLastUpdated" />
            </b-tab>
        </b-tabs>

    </div>
</template>

<script>
import mixins from '@/mixins';
import ObjectInfo from '@/components/ObjectInfo.vue';
import JsonItemEditor from '@/components/JsonItemEditor.vue'
export default {
  name: 'Tag',
  mixins: [mixins],
  components: {
      ObjectInfo,
      JsonItemEditor
  },
  data() {
    return {

        info: '',
        error: '',

        tagId: this.$route.params.tagId,
        tag: {},
        loading: true,
        tagLastUpdated: ''
    }
  },
  mounted () {

      this.reloadTag()

  },
  methods: {
    deleteTags(){
      if(confirm("are you sure you want to delet this tag")){
        this.automationApiRequest(
          `/cacmdb/tags/${this.tagId}`,
          'delete',
        ).then(() => {

          this.info = 'Tag successfully deleted'
          this.error = ''
          this.loading = false
        }).catch(err =>{

          this.loading = false
          this.error = this.getErrorString(err)
          this.info = ''

        })
        }
    },

    reloadTag() {
        
        this.loadingMessage = "Loading tag..."
        // load account
        this.automationApiRequest('/cacmdb/tags/' + this.tagId, 'get').then(data => {
            this.tag = data
            this.loading = false
            this.tagLastUpdated = new Date().toString()
        }).catch(err => {
            this.error = this.getErrorString(err)
        })

    },
    updateTag(update) {
      this.error = ''
      this.info = ''
      this.loadingMessage = "Updating tag..."
      this.loading = true
      this.automationApiRequest(
        `/cacmdb/tags/${this.tagId}`,
        'put',
        update
      ).then(() => {

        this.info = 'Tag successfully updated'
        this.error = ''
        this.loading = false
        this.reloadTag()

      }).catch(err =>{

        this.loading = false
        this.error = this.getErrorString(err)
        this.info = ''

      })
    }
  }
}
</script>