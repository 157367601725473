<template>
  <div class="main">
    
    <!-- alert banner -->
    <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

    <div v-if="loading" style="text-align: center;">
      <b-spinner type="grow" label="Loading..."></b-spinner> Starting session...
    </div>
    <div v-else>
      <h4 style="text-align: center;">{{ resource.cloudresourceid }} ({{ resource.name }}) [<b-icon icon="flag" /> {{ resource.region }}]</h4>
      <SsmSessionTerminal 
        :webSocketUrl="terminalConnection.webSocketUrl" 
        :token="terminalConnection.token"
        :terminateSessionEndpoint="terminalConnection.terminateSessionEndpoint"
        :sessionId="terminalConnection.sessionId"
      />
      <h4 style="text-align: center;"><strong>SSM Session ID:</strong> {{ terminalConnection.sessionId }} </h4>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins';
import SsmSessionTerminal from '@/components/SsmSessionTerminal.vue'
export default {
  name: 'SsmSession',
  mixins: [mixins],
  components: {
    SsmSessionTerminal
  },
  data () {
    return {
      error: '',
      loading: true,
      resourceId: this.$route.params.resourceId,
      resource: {},
      terminalConnection: {
        sessionId: '',
        terminateSessionEndpoint: '',
        webSocketUrl: '',
        token: ''
      }
    }
  },
  mounted () {
    this.getConnection()
  },
  methods: {
    getConnection() {
      
      this.loading = true
      
      // get the resource
      this.automationApiRequest(
        `/cacmdb/resources/${this.resourceId}`,
        'get'
      ).then(resource => {

        this.resource = resource

        // start a session
        this.automationApiRequest(
          `/sesame/aws/${resource.accountid}/${resource.region}/ssm/start-session`,
          'post',
          {Target: resource.cloudresourceid}
        ).then(response => {

          // done
          this.terminalConnection.token = response.TokenValue
          this.terminalConnection.webSocketUrl = response.StreamUrl
          this.terminalConnection.terminateSessionEndpoint = `/sesame/aws/${resource.accountid}/${resource.region}/ssm/terminate-session`
          this.terminalConnection.sessionId = response.SessionId
          this.loading = false
          this.error = ''

        // start session failed
        }).catch(err => {
          this.error = this.getErrorString(err)
          this.loading = false
        })

      // get resource failed
      }).catch(err => {
        this.error = this.getErrorString(err)
        this.loading = false
      })

    }
  }
}
</script>

<style scoped>
.main {
    margin: 0 auto;
    padding: 2rem;
}
</style>