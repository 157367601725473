<template>
    <div style="margin: 0 auto; max-width: 100rem">

        <!-- alert banner -->
        <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

        <p>Perseus Jobs</p>
        
        <!-- loader -->
        <div v-if="loading" class="loading">
            <b-spinner type="grow" label="Loading..."></b-spinner> Loading jobs..
        </div>

        <!-- table -->
        <b-table 
            v-else 
            striped hover 
            :items="perseusJobs" 
            :fields="perseusJobFields" 
            :sort-by="'DateReceived'" 
            :sort-desc="true"
            class="o2-clickable-table"
            @row-dblclicked="navigateToPerseusJob" 
            show-empty
            responsive
            stacked="md"
        >
        </b-table>
    </div>
</template>
<script>
import mixins from '@/mixins';
export default {
  name: 'AccountBasilTable',
  mixins: [mixins],
  props: {
      accountId: {
          required: true
      }
  },
  data () {
    return {
        error: '',
        loading: true,
        perseusJobs: [],
        perseusJobFields:[
            {
                "key" : "AccountId",
                "sortable" : true
            },
            {
                "key" : "DateReceived",
                "sortable" : true
            },
            {
                "key" : "Status",
                "sortable" : true
            },
            {
                "key" : "Action",
                "sortable" : true
            },
            {
                "key" : "Creator",
                "sortable" : true
            },
            {
                "key" : "Type",
                "sortable" : true
            },
            {
                "key" : "Tasks",
                "sortable" : false
            }
        ]
    }
  },
  computed : {},
  mounted () {

    // load perseus jobs
    this.automationApiRequest('/perseus/jobs?limit=30&AccountId=' + this.accountId, 'get').then(data => {
        this.perseusJobs = data.Items.map(job => {
            if (job.Tasks) {
                job.Tasks = Object.keys(job.Tasks).join(", ")
            }
            return job
        })
        this.perseusJobs = this.perseusJobs.map(job => this.dotPathObject(job))
        this.loading = false
    }).catch(err => {
        this.error = this.getErrorString(err)
        this.loading = false
    })

  },
  methods: {
      navigateToPerseusJob(record) {
          this.$router.push({ path: `/perseus/jobs/${record.id}` })
          this.$router.go(1)
      }
  }
}
</script>