<template>
    <div style="margin: 0 auto; max-width: 100rem">

        <!-- alert banner -->
        <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

        <!-- loader -->
        <div v-if="loading" class="loading">
            <b-spinner class="o2-bt-spin-sz" type="grow" label="Loading..."></b-spinner> Loading items...
        </div>

        <b-row>

            <!-- no resources message -->
            <div v-if="sessions.length === 0 && !loading" style="text-align: center; margin: 0 auto;">
              <p>No sessions found.</p>
            </div>

            <!-- table -->
            <b-table 
                v-if="sessions.length !== 0 && !loading" 
                striped hover 
                :items="sessions" 
                :fields="sessionFields"
                :sort-by="'StartDate'"
                show-empty
                responsive
                bordered
                stacked="md"
            >
              <!-- template for close session modal -->
              <template #cell(Stop)="data">
                  <b-button pill variant="outline-danger" size="sm" @click="showStopModal(data.item, data.index, $data.target)" class="mr-1">
                    Stop session
                  </b-button>
              </template>

            </b-table>
        </b-row>

      <!-- Stop session modal -->
      <b-modal 
        :id="stopModal.id" 
        :title="stopModal.title"
        size="md" hide-header
        @ok="stopSession(stopModal.SessionId)"
      >
        {{ stopModal.title }}
      </b-modal>

    </div>
</template>
<script>
import mixins from '@/mixins';
export default {
  name: 'InstanceSsmSessionList',
  mixins: [mixins],
  components: {},
  props: {
      accountId: {
          required: true
      },
      resourceId: {
          required: true
      },
      region: {
          required: true
      }
  },
  data () {
    return {
        error: '',
        loading: true,
        sessions: [],
        sessionFields: [
            {
                "key" : "Stop",
                "sortable" : true
            },
            {
                "key" : "SessionId",
                "sortable" : true
            },
            {
                "key" : "Target",
                "sortable" : true
            },
            {
                "key" : "Status",
                "sortable" : true
            },
            {
                "key" : "StartDate",
                "sortable" : true
            },
            {
                "key" : "Owner",
                "sortable" : true
            }
        ],
        stopModal: {
          title: "",
          id: "stop-modal",
          SessionId: ""
        }
    }
  },
  mounted () { 
    this.reload()
  },
  methods : {
    reload() {
      this.loading = true
      this.automationApiRequest(
          `/sesame/aws/${this.accountId}/${this.region}/ssm/describe-sessions`,
          'post',
          {
            State: "Active",
            Filters: [
              {
                "key" : "Target",
                "value" : this.resourceId
              }
            ]
          }
      ).then(response => {
        this.loading = false
        this.sessions = response.Sessions
      }).catch(err => {
        this.loading = false
        this.error = this.getErrorString(err)
      })
    },
    stopSession(sessionId) {
      this.loading = true
      this.automationApiRequest(
        `/sesame/aws/${this.accountId}/${this.region}/ssm/terminate-session`,
        'post',
        {
          SessionId: sessionId
        }
      ).then(() => {
        this.reload()
      }).catch(err => {
        this.loading = false
        this.error = this.getErrorString(err)
      })
    },
    showStopModal(item, index, button) {
      this.stopModal.title = `Stop session ${item.SessionId} ?`
      this.stopModal.SessionId = item.SessionId
      this.$root.$emit('bv::show::modal', this.stopModal.id, button)
    },
  }
}
</script>



<style scoped>
</style>