<script>  
import { HorizontalBar } from 'vue-chartjs'

export default {
  name: 'AssignmentGraph',
  extends: HorizontalBar,
  props: {
    rankings: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      nItemsPlot: 30,
      chartdata: {
        labels: [],
        datasets: [
          {
            label: 'Scaled score',
            backgroundColor: '#f87979',
            data: []
          },
          // {
          //   label: 'Raw score',
          //   backgroundColor: '#1594d4',
          //   data: []
          // }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scaleShowValues: true,
        scales: {
          yAxes: [{
            ticks: {
              autoSkip: false
            }
          }]
        }
      }
    }
  },
  beforeMount() {
    var i;
    for (i = 0; i < this.nItemsPlot; i++) {
      let team = this.rankings[`${i}`]
      if (!team) { break; }
      this.chartdata.datasets[0].data.push(team['ScaledScore'])
      // this.chartdata.datasets[0].data.push(team['RawScore'])
      this.chartdata.labels.push(team['TeamName'])
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

<style>
</style>