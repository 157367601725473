<template>
  <div class="main">

    <!-- alert banner -->
    <b-alert variant="danger" v-if="error" show>{{ error }}</b-alert>

    <div v-if="!loading">
        <SaffronReportTrend :dataPoints="contractMetrics"/>
    </div>
    <div v-else>
        <div :key="`loadingMessage-${loadingMessage}`" class="center-text">
            <b-spinner type="grow" label="Loading..."></b-spinner> {{ loadingMessage }}
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import mixins from '@/mixins';
import SaffronReportTrend from '@/components/graphs/SaffronReportTrend.vue'
export default {
  name: 'AwsSaffronTrend',
  mixins: [mixins],
  components: {
    SaffronReportTrend
  },
  data () {
      return {
        error: '',
        loading: true,
        loadingMessage: 'Pulling metrics...',
        contractMetrics: []
    }
  },
  mounted () {
      this.loadContractMetrics().then(() => {
          this.loading = false
        }).catch(err => {
            console.log(err)
            this.assignmentRankings = {}
            this.error = this.getErrorString(err)
        })
  },
  methods :{
        async loadContractMetrics () {
            
            this.loading = true
            let contracts = await this.automationApiRequest(
                '/saffron/contracts?EntityId=SaffronSystem&ConfigurationType=AwsContractsReport',
                'get'
            )

            for await (const page of this.paginateAutomationApi(
                '/saffron/jobs',
                {ContractId: contracts.Items[0].ContractId}
            )){
                for (const job of page.Items){
                    if (job.Outputs && job.Outputs.ContractOkPercentage) {
                        this.contractMetrics.push({
                            DateCreated: job.DateCreated,
                            ContractOkPercentage: job.Outputs.ContractOkPercentage,
                            ContractErrorCount: job.Outputs.ContractErrorCount
                        })
                    }
                }
            }

        }
    }
}
</script>

<style scoped>
.main {
    margin: 5rem auto;
    max-width: 100rem;
}

.center-text {
    width: 100%;
    text-align: center;
}

.fade-enter-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>